.modalWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 101;
  .modal {
    padding: 0 24px 24px;
    background: var(--white);
    border-radius: 12px;
    max-width: 572px;
    position: relative;
    max-height: 75%;
    overflow: scroll;
    outline: none;
  }
  ::-webkit-scrollbar {
    width: 0;
  }
}

@media screen and (max-width: 600px) {
  .popUpWrapper {
    .modal {
      width: 96%;
    }
  }
}
