.promocode {
  margin-top: 8px;
  margin-bottom: 24px;
  width: 100%;
  .applyBtn {
    outline: none;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: var(--primary600);
    &:disabled {
      color: var(--gray500);
      cursor: not-allowed;
    }
  }
  .title {
    margin-bottom: 6px;
    font-size: 14px;
    line-height: 20px;
    font-family: var(--font-family-medium);
    color: var(--gray700);
  }
  .wrapperField {
    gap: 8px;
    padding: 0 14px;
    display: flex;
    border: 1px solid var(--gray300);
    align-items: center;
    width: 100%;
    border-radius: 8px;
    position: relative;
    .customInput {
      flex: 1;
      padding: 10px 0;
      width: 100%;
      border-radius: 8px;
      font-size: 16px;
      height: auto;
      line-height: 24px;
      outline: none;
      border: none;
      div {
        input {
          padding: 10px 38px 10px 42px;
        }
        fieldset {
          border: none;
        }
      }
    }
    .userIcon {
      color: var(--gray500);
      font-size: 20px;
    }
    .helpIconWrap {
      height: 16px;
      cursor: pointer;
      .customTooltip {
        position: absolute;
        visibility: hidden;
        width: 320px;
        right: -30px;
        top: -60px;
      }
    }
    .helpIcon {
      color: var(--gray400);
      font-size: 16px;
    }
    .helpIconWrap:hover .customTooltip {
      visibility: visible;
    }
    .helpIconWrap:active .customTooltip {
      visibility: visible;
    }
  }
  .error {
    display: inline-block;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 500;
    color: var(--error);
  }
}
