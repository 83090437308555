.notificationWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 101;
  li {
    margin-top: 8px;
    list-style: none;
  }
  ::-webkit-scrollbar {
    width: 0;
  }
  .modal {
    min-width: 400px;
    transition: all 0.5s ease;
    position: fixed;
    inset: 0;
    margin: auto;
    width: 350px;
    height: fit-content;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 12px;
    background: #ffffff;
    z-index: 101;
    box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03),
      0 20px 24px -4px rgba(16, 24, 40, 0.08);
    opacity: 1;
    &Button {
      transition: all 0.3s;
      margin-top: 32px;
      width: 100%;
      padding: 10px 18px;
      border-radius: 8px;
      background: #7f56d9;
      border: 1px solid #7f56d9;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: #ffffff;
      &:hover {
        border: 1px solid #6941c6;
        background: #6941c6;
        box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
      }
      &:active {
        border: 1px solid #7f56d9;
        background: #7f56d9;
        box-shadow: 0 0 0 4px #f4ebff, 0 1px 2px 0 rgba(16, 24, 40, 0.05);
      }
    }
  }
  .topModal {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    .buttonClose {
      min-width: auto;
      display: flex;
      width: max-content;
      border: none !important;
      padding: 0;
      font-size: 24px;
      color: var(--gray500);
    }
  }
  .modalIconWrapper {
    border-radius: 50%;
    border: 8px solid #ecfdf3;
    background: #d1fadf;
    padding: 8px;
  }
  .modalTitle {
    font-family: var( --font-family-bold);
    color: var( --black3);
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
    margin-top: 20px;
    margin-bottom: 12px;
  }
}
.errorModal {
  .modalIconWrapper {
    border: 8px solid #fef3f2;
    background: #fee4e2;
  }
}
.warningModal {
  .modalIconWrapper {
    border: 8px solid #fffaeb;
    background: #fef0c7;
  }
}

@media screen and (max-width: 600px) {
  .popUpWrapper {
    .modal {
      width: 96%;
    }
  }
}