.overlay {
  transition: all 0.5s;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: linear-gradient(180deg, rgba(22, 22, 74, 0) 0%, #09061c 44.31%);
  backdrop-filter: blur(4px);
}
.hideOverlay {
  transition: all 0.5s;
  top: auto;
  left: auto;
  width: 0;
  height: 0;
}
.cookiesBar {
  transition: all 0.5s;
  z-index: 1001;
  background-color: #fcfaff;
  width: 100%;
  position: fixed;
  bottom: 0;
  .container {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 32px;
  }
  &Content {
    display: flex;
    align-items: center;
  }
  &IconWrapper {
    padding: 10px;
    background: #f4ebff;
    border-radius: 50%;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 8px solid #f9f5ff;
  }
  &Text {
    color: #6941c6;
    font-size: 16px;
    line-height: 24px;
    a {
      text-decoration: underline;
    }
  }
  &Buttons {
    display: flex;
  }
  &Button {
    transition: all 0.3s;
    margin-left: 8px;
    padding: 10px 18px;
    background: #7f56d9;
    border: 1px solid #7f56d9;
    color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    &:hover {
      background: #6941c6;
    }
    &:focus {
      background: #7f56d9;
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px #f4ebff;
    }
    &:first-child {
      background: #ffffff;
      border: 1px solid #d0d5dd;
      color: #344054;
      &:hover {
        background: #f9fafb;
      }
      &:focus {
        background: #f9f5ff;
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px #f2f4f7;
      }
    }
  }
  &ButtonClose {
    padding: 10px 14px;
    margin-left: 8px;
    border-radius: 8px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: #f9f5ff;
    }
    &:focus {
      background: #f9f5ff;
      box-shadow: 0 0 0 4px #f4ebff;
    }
  }
}
.hideCookiesBar {
  transition: all 0.5s;
  width: 0;
  bottom: auto;
}

@media screen and (max-width: 768px) {
  .cookiesBar {
    .container {
      padding: 12px 16px;
      flex-direction: column;
    }
    &Content {
      padding-right: 45px;
    }
    &IconWrapper {
      display: none;
    }
    &Text {
      display: flex;
      flex-direction: column;
      line-height: 20px;
    }
    &Buttons {
      justify-content: space-around;
      width: 100%;
    }
    &Button {
      margin-left: 0;
      margin-top: 10px;
      padding: 8px 12px;
      width: 35%;
    }
    &ButtonClose {
      position: absolute;
      right: 0;
      top: 16px;
    }
  }
}
