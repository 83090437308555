@font-face {
  font-family: 'InterThin';
  font-display: swap;
  src: url(../public/fonts/Inter-Thin.ttf);
}

@font-face {
  font-family: 'InterLight';
  font-display: swap;
  src: url(../public/fonts/Inter-Light.ttf);
}

@font-face {
  font-family: 'InterRegular';
  font-display: swap;
  src: url(../public/fonts/Inter-Regular.ttf);
}

@font-face {
  font-family: 'InterMedium';
  font-display: swap;
  src: url(../public/fonts/Inter-Medium.ttf);
}

@font-face {
  font-family: 'InterSemiBold';
  font-display: swap;
  src: url(../public/fonts/Inter-SemiBold.ttf);
}

@font-face {
  font-family: 'InterBold';
  font-display: swap;
  src: url(../public/fonts/Inter-Bold.ttf);
}

@font-face {
  font-family: 'InterExtraLight';
  font-display: swap;
  src: url(../public/fonts/Inter-ExtraLight.ttf);
}

@font-face {
  font-family: 'InterExtraBold';
  font-display: swap;
  src: url(../public/fonts/Inter-ExtraBold.ttf);
}

@font-face {
  font-family: 'InterBlack';
  font-display: swap;
  src: url(../public/fonts/Inter-Black.ttf);
}

@font-face {
  font-family: 'IcelandRegular';
  font-display: swap;
  src: url(../public/fonts/Iceland-Regular.ttf);
}

// TODO: should be reviewed and cleaned up
:root {
  --startMob: 767px;
  --font-family-thin: 'InterThin';
  --font-family-light: 'InterLight';
  --font-family-regular: 'InterRegular';
  --font-family-medium: 'InterMedium';
  --font-family-semibold: 'InterSemiBold';
  --font-family-bold: 'InterBold';
  --font-family-extralight: 'InterExtraLight';
  --font-family-extrabold: 'InterExtraBold';
  --font-family-black: 'InterBlack';
  --font-family-iceland-regular: 'IcelandRegular';
  --main-gradient: linear-gradient(94.72deg, #F16097 0%, #6324EB 98.35%);
  --bgGradient: linear-gradient(221.58deg, rgba(233, 0, 201, 0.3) 0.96%, rgba(10, 7, 29, 0) 55.12%, rgba(150, 0, 162, 0.3) 100%), linear-gradient(319.1deg, #1D1D60 -1.77%, rgba(10, 7, 29, 0) 51.62%, #201D65 100%), #0A071D;
  --bg-gradientLink: linear-gradient(94.72deg, #F16097 0%, #6324EB 98.35%);
  --bg-primary500: rgba(66, 48, 125, 0.5);
  --bg-primary100: rgba(66, 48, 125, 0.1);
  --bg-cartWhite: rgba(255, 255, 255, 0.05);
  --error: #F04438;
  --white: #ffffff;
  --black3: #282828;
  --black2: #1D1D1D;
  --primary: #6E2DFA;
  --primary900: #42307D;
  --primary800: #53389E;
  --primary700: #6941C6;
  --primary600: #7F56D9;
  --primary400: #B692F6;
  --primary200: #E9D7FE;
  --primary100: #F4EBFF;
  --primary50: #F9F5FF;
  --gray1000: #09061C;
  --gray800: #1d2939;
  --gray700: #344054;
  --gray600: #475467;
  --gray500: #667085;
  --gray400: #98A2B3;
  --gray300: #D0D5DD;
  --gray200: #EAECF0;
  --gray100: #F2F4F7;
  --gray50: #F9FAFB;
  --gray25: #FCFCFD;
  --gray: #9B98B3;
  --bg-dark-700: rgba(16, 24, 40, 0.86);
  --shadow-xs: rgba(16, 24, 40, 0.05);
  --shadow-xs1: rgba(0, 0, 0, 0.3);
  --shadow-xs2: rgba(16, 24, 40, 0.05);
  --bg3: hsla(247deg, 64%, 7%, 1);
  --Indigo1000: #231642;
  --Indigo700: #3538CD;
  --Indigo50: #EEF4FF;
  --bg4: #141224;
  --green: #10B981;
  --blue700: #175CD3;
  --blue500: #2E90FA;
  --blue50: #EFF8FF;
  --blueLight50: #F0F9FF;
  --bgCart1: linear-gradient(86.38deg, #E05CA1 0%, #5F19C5 102.68%);
  --bgCart2: linear-gradient(128.81deg, #5F19C5 39.53%, #B346BC 94.61%);
  --bgCart3: linear-gradient(93.67deg, #AA41BE 5.91%, #FE817C 92.43%);
  --bgBox2: rgba(255, 255, 255, 0.6);
  --bgOrange: #FC7700;
  --textBase2: #667085;
  --wzoneBg2: #121b34;
  --bgFreeCart: rgba(29, 25, 49, 0.7);
  --bgCartWhiteList: #17152a;
  --bgBlur: rgba(255, 255, 255, 0.24);
  --bgBorder: rgba(255, 255, 255, 0.3);
  --colorNickname: rgba(255, 255, 255, 0.79);
  --purple500: #7a5af8;
  --purple25: #fafaff;
  --bgLink: #131126;
  --primary25: #fcfaff;
  --purple900: #3e1c96;
  --purple100: #ebe9fe;
  --warning: #fec84b;
  --ux60: #8E99AB;
  --titleColor: #fbfbfb;
  --bgBox1Custom1: #6ce9a6;
  --bgBox2Custom1: #b692f6;
  --inner-gradient-bg: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(180deg, #0D0924 0%, #17103F 32.08%, #140E38 50.31%, #140E38 66.15%, #0A071C 100%);
  --bgBasket: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    linear-gradient(94.72deg, #f16097 0%, #6324eb 98.35%);
    --wzoneBg1: #1D1931;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
button {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}
body {
  font-family: var(--font-family-regular), sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}