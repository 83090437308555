.dearPlate {
  position: relative;
  width: 100%;
  background: linear-gradient(90deg, #ac32e4 0%, #7918f2 48%, #4801ff 100%);
  padding: 8px 50px 8px 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  z-index: 100;
}
.link {
  color: #ffffff;
  text-decoration: underline;
}
.close{
  position: absolute;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
