.button {
  text-transform: initial !important;
  font-size: 16px !important;
  line-height: 24px !important;
  font-family: var(--font-family-semibold) !important;
  border-radius: 8px !important;
  padding: 10px 18px !important;
}
.primary {
  display: flex !important;
  align-items: center;
  transition: all 0.3s;
  text-transform: initial !important;
  color: var(--white) !important;
  background: var(--bg-gradientLink) !important;
  box-shadow: 0 1px 2px var(--shadow-xs2) !important;
  &:hover {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.15),
        rgba(255, 255, 255, 0.15)
      ),
      linear-gradient(94.72deg, #f16097 0%, #6324eb 98.35%) !important;
  }
}
.secondary {
  transition: all 0.3s;
  width: 100%;
  background-color: #7f56d9 !important;
  border: 1px solid #7f56d9 !important;
  color: #ffffff !important;
  &:hover {
    border: 1px solid #6941c6 !important;
    background: #6941c6 !important;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  }
  &:active {
    border: 1px solid #7f56d9 !important;
    background: #7f56d9 !important;
    box-shadow: 0 0 0 4px #f4ebff, 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  }
}
.outline {
  background-color: white !important;
  border: 1px solid var(--gray300) !important;
  box-shadow: 0 1px 2px var(--shadow-xs2) !important;
  color: var(--gray700) !important;
  text-transform: initial !important;
  &:hover {
    background-color: var(--gray100) !important;
  }
}
.disabled {
  background: #E9D7FE;
  border: 1px solid #E9D7FE;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}
