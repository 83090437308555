.basketContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  .quantityContainer {
    width: 100%;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    .quantityInputWrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: max-content;
    }
    .quantityLabel {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
    }
    .total {
      min-width: 80px;
      display: inline-flex;
      align-items: center;
      height: 100%;
      background: var(--gray100);
      padding: 8px 20px;
      font-weight: 600;
      border-radius: 8px;
    }
  }
  .topContent {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;
    padding: 8px 8px 0;
    .item {
      width: 50%;
      .code,
      .title {
        font-size: 20px;
        line-height: 30px;
        color: var(--gray800);
      }
      .title {
        margin-bottom: 8px;
        font-family: var(--font-family-medium);
      }
      .code {
        font-family: var(--font-family-iceland-regular);
      }
      .price {
        font-size: 24px;
        line-height: 32px;
        text-align: right;
        font-family: var(--font-family-medium);
        color: #1d2939;
        img{
          margin-left: 10px;
          margin-right: 2px;
        }
      }
    }
  }
  .label {
    padding: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
    width: 100%;
    transition: 0.5s all;
    margin-bottom: 8px;
    &:hover {
      background: var(--gray100);
      .box {
        border: 2px solid var(--gray500);
        color: var(--gray500);
      }
    }
    &.active {
      .box {
        transform: rotate(180deg);
        border: 2px solid var(--gray500);
        color: var(--gray500);
      }
    }
    .box {
      transition: 0.5s all;
      padding: 9px 0;
      width: 22px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid var(--gray300);
      border-radius: 4px;
      margin-right: 8px;
      color: var(--gray300);
      svg {
        font-size: 20px;
      }
    }
    .textLabel {
      font-family: var(--font-family-medium);
      font-size: 14px;
      line-height: 20px;
      color: var(--gray800);
    }
  }
  .chains{
    position: relative;
    width: 100%;
    border:1px solid #D0D5DD;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    padding: 10px;
    color: #667085;
    font-size: 16px;
    line-height: 24px;
    img{
      margin-right: 8px;
      &:last-child{
        margin-right: 2px;
      }
    }
    .helpIconWrap {
      z-index: 1;
      position: absolute;
      cursor: pointer;
      right: 80px;
      top: 12px;
      .customTooltip {
        position: absolute;
        visibility: hidden;
        text-align: center;
        width: 350px;
        right: -30px;
        top: -60px;
      }
    }
    .helpIcon {
      color: var(--gray400);
      font-size: 16px;
    }
    .helpIconWrap:hover .customTooltip {
      visibility: visible;
    }
    .helpIconWrap:active .customTooltip {
      visibility: visible;
    }
  }
  .quantityWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    .fieldLabel {
      font-size: 14px;
      line-height: 20px;
      width: 100%;
      color: var(--gray700);
      margin: 0 0 8px 8px;
    }
    .quantityInputWrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 20px;
    }
    .totalPriceBox {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 600;
      background-color: var(--gray100);
      border-radius: 8px;
      color: var(--gray800);
      height: 40px;
    }
  }
  .paymentInfo {
    margin-bottom: 16px;
    border-top: 1px solid var(--gray300);
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .itemRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 0;
      padding-left: 6px;
      margin-left: auto;
      &:not(:first-child) {
        border-top: 1px solid var(--gray300);
      }
      h5 {
        font-size: 12px;
        line-height: 18px;
        font-family: var(--font-family-bold);
        color: var(--gray800);
        img{
          margin-left: 6px;
          margin-right: 2px;
        }
      }
      p {
        font-size: 12px;
        margin-right: 16px;
        line-height: 18px;
        color: var(--gray700);
      }
    }
  }
  .buttonBasket {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.2)
      ),
      linear-gradient(94.72deg, #f16097 0%, #6324eb 98.35%);
    padding: 10px 0;
    font-size: 16px;
    line-height: 24px;
    font-family: var(--font-family-semibold);
    color: #ffffff;
    svg {
      margin-right: 8px;
      font-size: 20px;
    }
    &:hover {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.2),
          rgba(255, 255, 255, 0.2)
        ),
        linear-gradient(94.72deg, #6324eb 0%, #6324eb 98.35%);
    }
  }
  .buttonBasketDisabled{
    background: #E9D7FE;
    border: 1px solid #E9D7FE;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  }
  .buttonQuestion {
    margin-left: 8px;
    width: 50px;
    min-width: 30px;
  }
  .bottomContent {
    margin-top: 16px;
    justify-content: center;
    display: flex;
    font-size: 12px;
    line-height: 18px;
    color: var(--gray700);
    align-items: center;
    img {
      margin-left: 4px;
    }
  }
}
.modalControlWrapper {
  display: flex;
  width: 100%;
  gap: 12px;
  margin-top: 32px;
}