.container {
    max-width: fit-content;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--gray300);
    border-radius: 8px;
    padding: 8px;
    height: 40px;
    background-color: var(--white);
    .button {
      width: 24px;
      font-size: 30px;
      line-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      color: var(--primary700);
      border: none;
      border-radius: 6px;
      cursor: pointer;
      outline: none;
      transition: .3s ease-out;
      &:hover {
        background-color: var(--primary200);
      }
      &:disabled {
        color: var(--gray300);
        cursor: not-allowed;
        &:hover {
          background-color: transparent;
        }
      }
    }
    .input {
      width: 40px;
      padding: 0 3px;
      text-align: center;
      border: none;
      font-size: 16px;
      outline: none;
      background-color: transparent;
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    .maxButton {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px 12px;
      font-size: 14px;
      font-weight: 600;
      color: var(--primary700);
      background-color: var(--primary50);
      border: none;
      border-radius: 6px;
      margin-left: 10px;
      cursor: pointer;
      outline: none;
      transition: .3s ease-out;
      &:hover {
        background-color: var(--primary200);
      }
      &:disabled {
        color: var(--gray300);
        cursor: not-allowed;
        &:hover {
          background-color: var(--primary50);;
        }
      }
  
      @media (min-width: 768px) {
        margin-left: 20px;
      }
    }
  }
  .maxSelectedMsg {
    display: flex;
    gap: 8px;
    font-size: 14px;
    color: var(--primary700);
    background-color: var(--primary50);
    padding: 4px;
    font-weight: 500;
    border-radius: 8px;
    p {
      width: 75%;
    }
  }